import React from 'react';
import { graphql } from 'gatsby';
import Timeline from '../components/timeline/Timeline';

export default function HomePage({ data }) {
  return <Timeline data={data} />;
}

export const query = graphql`
  query {
    projects: allSanityProject {
      nodes {
        id
        year
        title
        shortTitle
        category
        slug {
          current
        }
        gallery {
          images {
            asset {
              id
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          foto {
            asset {
              id
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          disegni {
            asset {
              id
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          partiture {
            asset {
              id
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
