import React, { useContext, useEffect, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { StyledTimeline } from "./Timeline.styled";
import ScrollContext from "../ScrollContext";

const cats = [
  "musica",
  "pittura",
  "film",
  "video",
  "teatro",
  "grafica",
  "design",
];

export default function Timeline({ data }) {
  const { nodes } = data.projects;
  const x = nodes.filter(({ category }) => category === "illustrazione");
  console.log(x);
  const projects = data.projects.nodes;
  const years = projects.map((project) => project.year);
  const yearSet = [...new Set(years)].sort().reverse();

  const [scrollPosition, setScrollPosition] = useContext(ScrollContext);

  const handleScroll = (e) => {
    const position = e.target.scrollLeft;
    setScrollPosition(position);
  };

  useEffect(() => {
    const tl = document.querySelector(".timeline");
    tl.addEventListener("scroll", handleScroll, { passive: true });

    tl.scrollLeft = scrollPosition;

    return () => {
      tl.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <StyledTimeline>
      <div className="timeline" style={{ height: `calc(100vh - 75px` }}>
        <div className="cats">
          {cats.map((cat, i) => (
            <p className={`cat ${cat}`} key={i}>
              {cat.replace(/^\w/, (c) => c.toUpperCase())}
            </p>
          ))}
          <div className="empty" />
        </div>
        <div className="main">
          {
            /* render a column for each year */
            yearSet.map((year) => (
              <div className="col" key={year}>
                {
                  /* render a row for each category  */
                  cats.map((cat, i) => (
                    <div className={`row ${cat}`} key={i}>
                      {
                        /* render items for each cat */
                        projects
                          .filter((project) => {
                            const category =
                              cat === "grafica" ? "illustrazione" : cat;
                            return (
                              project.year === year &&
                              project.category === category
                            );
                          })
                          .map((filteredProject) => (
                            <div className="item" key={filteredProject.id}>
                              <Link
                                to={`projects/${filteredProject.slug.current}`}
                              >
                                <div className="img-container">
                                  <GatsbyImage
                                    image={
                                      filteredProject.gallery.images[0]?.asset
                                        .gatsbyImageData ||
                                      filteredProject.gallery.foto[0]?.asset
                                        .gatsbyImageData ||
                                      filteredProject.gallery.disegni[0]?.asset
                                        .gatsbyImageData ||
                                      filteredProject.gallery.partiture[0]
                                        ?.asset.gatsbyImageData
                                    }
                                    alt={filteredProject.title}
                                  />
                                </div>
                                <div className="title">
                                  <p>{filteredProject.shortTitle}</p>
                                </div>
                              </Link>
                            </div>
                          ))
                      }
                    </div>
                  ))
                }
                <p className="row year">{year}</p>
              </div>
            ))
          }
        </div>
      </div>
    </StyledTimeline>
  );
}
