import styled from "styled-components";

export const StyledTimeline = styled.section`
  width: 100%;

  .timeline {
    height: 100%;
    display: grid;
    grid-template-columns: 28rem 1fr;
    overflow: scroll;

    @media (max-width: 1023px) {
      grid-template-columns: 20rem 1fr;
    }

    @media (max-width: 700px) {
      grid-template-columns: 1fr 1fr;
    }

    .main .col:first-of-type .row .item .title {
      border-left: none;
    }

    .cats,
    .main .col {
      display: grid;
      grid-template-rows: repeat(7, calc((100% - 7.5rem) / 7)) 7.5rem;
      background: var(--white);
    }

    .cats {
      border-right: var(--border);
      position: sticky;
      left: 0;
      z-index: 9999;

      .cat {
        padding: 0 var(--padding-medium);
        display: flex;
        align-items: center;
        background: var(--white);

        &:nth-child(-n + 6) {
          border-bottom: var(--border);
        }
      }

      .empty {
        position: sticky;
        bottom: 0;
        background: var(--white);
        z-index: -1;
        border-top: var(--border);
      }
    }

    .main {
      display: flex;
      .col {
        height: 100%;
        min-width: 168px;
        grid-template-areas:
          "musica"
          "pittura"
          "film"
          "video"
          "teatro"
          "illustrazione"
          "design"
          "year";

        .row {
          display: flex;

          &:nth-child(-n + 6) {
            border-bottom: var(--border);
          }

          .item {
            position: relative;
            width: 200px;

            a {
              height: 100%;
            }

            .img-container,
            .gatsby-image-wrapper {
              transition: opacity 0.25s linear;
              object-fit: cover;
              height: 100%;
              width: 100%;
              position: absolute;
              z-index: 10;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .title {
              width: 100%;
              height: 100%;
              color: var(--black);
              padding: var(--padding-xsmall);
              position: absolute;
              top: 0;
              left: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              border-right: var(--border);
              border-left: var(--border);
            }

            &:hover {
              .img-container,
              .gatsby-image-wrapper {
                opacity: 0;
              }
            }
          }
        }

        .year {
          grid-area: year;
          padding: var(--padding-small) var(--padding-medium);
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--white);
          border-top: var(--border);
          border-right: var(--border);
        }
      }
    }
  }
`;
